<template>
	<v-dialog v-model="visibleDialog" scrollable>
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('berthplanner.waitingEtaCost.title') }}</span>
			</v-card-title>
			<v-divider />
			<v-card-text class="pt-3 d-flex">
				<v-container class="px-6 py-0" v-if="!loading && stops.length > 0">
					<table class="berthplannerTable">
						<tr class="headers">
							<th v-for="(header, i) in headers" :key="i" class="font-weight-bold berthplannerTh">{{ header.name }}</th>
						</tr>
						<tr v-for="stop in stops" :key="stop.id" class="berthplannerTr">
							<td v-for="(header, o) in headers" :key="o" class="berthplannerTd">
								{{ stop[header.id] != null ? formatValue(stop[header.id]) : '' }}
							</td>
						</tr>
					</table>
					<span class="align-right">
						<p class="mr-4 mt-0">Coste total: {{ costeTotal }}</p>
					</span>
				</v-container>
				<v-container class="px-6 py-0" v-if="!loading && stops.length == 0">
					<div>{{ $t('berthplanner.waitingEtaCost.noInfo') }}</div>
				</v-container>
				<v-container class="px-6 py-0" v-if="loading"> <v-progress-linear indeterminate rounded height="6"></v-progress-linear></v-container>
			</v-card-text>
			<!-- Page Footer -->
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn outlined @click="back">{{ $t('form.close') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			visibleDialog: false,
			versionId: {},
			loading: false,
			stops: [],
			headers: [
				{ id: 'voyage', name: this.$t('grid.shippositioning.voyage') },
				{ id: 'vesselname', name: this.$t('grid.shippositioning.vesselname') },
				{ id: 'etaVisit', name: this.$t('berthplanner.waitingEtaCost.etavisit') },
				{ id: 'laycanIni', name: this.$t('grid.shippositioning.laycanini') },
				{ id: 'laycanFin', name: this.$t('grid.shippositioning.laycanend') },
				{ id: 'diaryCost', name: this.$t('grid.shippositioning.diarycost') },
				{ id: 'startDate', name: this.$t('grid.shippositioning.etb') },
				{ id: 'endDate', name: this.$t('grid.shippositioning.etd') },
				{ id: 'etaGap', name: this.$t('grid.shippositioning.waitingHrs') },
				{ id: 'durationInGrains', name: this.$t('grid.shippositioning.operationHrs') },
				{ id: 'waitingEtaCost', name: this.$t('berthplanner.waitingEtaCost.waitingEtaCost') }
			],
			costeTotal: 0
		};
	},
	watch: {
		visibleDialog(val) {
			if (val) {
				this.loading = true;
				this.getCost();
			}
		}
	},
	mounted() {},
	methods: {
		getCost() {
			const opts = {
				model: 'berthingplanversion',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.versionId
						}
					]
				}
			};
			this.$puiRequests.postRequest(
				'/puisearch',
				opts,
				(response) => {
					if (response.data.data[0]) {
						this.stops = response.data.data[0].berthingjson.data;
						this.calculateCost();
						this.loading = false;
					}
				},
				(error) => {
					this.loading = false;
					console.error(error);
				}
			);
		},
		back() {
			this.$emit('response', false);
			this.visibleDialog = false;
		},
		conntinue() {
			//this.visibleDialog = false;
			this.loading = false;
		},
		calculateCost() {
			this.costeTotal = 0;
			for (let i = 0; i < this.stops.length; i++) {
				this.costeTotal = this.costeTotal + this.stops[i].waitingEtaCost;
			}
		},
		formatValue(value) {
			if (this.isDate(value)) {
				return this.formatDate(value);
			}
			return value;
		},
		isDate(value) {
			if (typeof value === 'string' && !isNaN(Date.parse(value))) {
				const date = new Date(value);
				return date instanceof Date && !isNaN(date);
			}
			return false;
		},
		formatDate(value) {
			const date = new Date(value);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const seconds = String(date.getSeconds()).padStart(2, '0');
			return `${day}-${month}-${year} ${hours}:${minutes}`;
		}
	}
};
</script>
<style lang="postcss">
@import '../../../styles/app-variables.pcss';

.title {
	color: var(--primary);
	font-size: 12px;
	font-family: Montserrat;
}
.berthplannerTable {
	border-collapse: collapse;
	width: 100%;
	background-color: #d7ebff;
	font-family: Montserrat;
}

.berthplannerTh {
	text-align: left;
	color: #6f7480;
	padding: 8px;
	font-weight: lighter;
}

.berthplannerTd {
	border: 1px solid #dadde0;
	text-align: left;
	padding: 2px;
	padding-left: 4px;
}

.berthplannerTr:nth-child(even) {
	background-color: white;
}

.berthplannerTr:nth-child(odd) {
	background-color: #f7f8fc;
}

.align-right {
	display: flex;
	justify-content: flex-end;
}
</style>
