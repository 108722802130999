<template>
	<div v-resize="onResize">
		<div id="bp_container"></div>

		<v-tooltip v-for="(stop, index) in stops" :key="index" right>
			<template v-slot:activator="{ on, attrs }">
				<div v-on="on" v-bind="attrs">
					<div v-if="stop.status != 1">
						<v-icon :id="'vesselTooltipIcon_' + stop.id" style="visibility: hidden !important; font-size: 20px">
							fas fa-info-circle
						</v-icon>
					</div>
				</div>
			</template>
			<berth-planner-vessel-tooltip :id="'vesselTooltip_' + stop.id" :stop="stops[index]" />
		</v-tooltip>

		<v-dialog v-model="vesselVisibleDialog" max-width="900" eager>
			<berth-planner-vessel-dialog
				v-if="vesselVisibleDialog"
				:vessel="vesselDialogModel"
				:userFunctionalities="userFunctionalities"
				:readOnly="readOnly"
				@close-dialog="closeVesselDialog"
			/>
		</v-dialog>

		<berth-planner-berth-block-dialog
			v-if="berthBlockVisibleDialog"
			v-model="berthBlockVisibleDialog"
			:currentBerth="currentBerth"
			:berthFilter="berthFilter"
			:userFunctionalities="userFunctionalities"
		/>
	</div>
</template>

<script>
import JointMain from '../jointjs/JointMain.js';
import JointBollardsBerthBoard from '../jointjs/components/bollardsberthplanner/JointBollardsBerthBoard.js';
import { JointBoardTypes, JointItemTypes } from '../jointjs/items/JointTypes.js';
import JointUtils from '../jointjs/utils/JointUtils.js';

import BerthPlannerVesselTooltip from './vessel/BerthPlannerVesselTooltip.vue';
import BerthPlannerVesselDialog from './dialogs/BerthPlannerVesselDialog.vue';
import BerthPlannerBerthBlockDialog from './dialogs/BerthPlannerBerthBlockDialog.vue';

import WeatherService from '@/services/weather.service.js';

export default {
	components: { BerthPlannerVesselTooltip, BerthPlannerVesselDialog, BerthPlannerBerthBlockDialog },
	props: {
		value: {
			type: Object,
			required: true
		},
		currentPort: {
			type: Object,
			default: () => ({})
		},
		currentDock: {
			type: Object,
			default: () => ({})
		},
		currentBerth: {
			type: Object,
			default: () => ({})
		},
		zoomFactor: {
			type: Number,
			required: true
		},
		statusItems: {
			type: Array,
			required: true
		},
		lineUpByOperationTypeEnabled: {
			type: Boolean,
			default: true
		},
		userFunctionalities: {
			type: Object,
			required: true
		},
		readOnly: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			debug: false,

			jointMain: null,
			jointBoard: null,

			now: new Date(),

			days: [],
			berths: [],
			stops: [],
			blocks: [],
			bookings: [],
			bollards: [],

			vesselVisibleDialog: false,
			berthBlockVisibleDialog: false
		};
	},
	computed: {
		onPuiExpandMenu() {
			return this.$store.state.menu.expandMenu;
		},
		onPuiSecondMenuShowed() {
			return this.$store.state.menu.secondMenuShowed;
		},
		bollardNodes() {
			if (!this.currentDock.correlativeberths) {
				return this.bollards.map((bollard) => [bollard]);
			}

			let bollardNodes = [];
			let newBollardPosition = [];

			for (let n = 0; n < this.bollards.length; n++) {
				newBollardPosition.push(this.bollards[n]);

				// si es el primero
				if (n == 0) {
					bollardNodes.push(newBollardPosition);
					newBollardPosition = [];
					continue;
				}

				// si este no es berthlimit o si el siguiente no es berthlimit
				if (this.bollards[n].berthlimit != true || (this.bollards[n + 1] && this.bollards[n + 1].berthlimit != true)) {
					bollardNodes.push(newBollardPosition);
					newBollardPosition = [];
					continue;
				}

				// si ya hay 2 bolardos en el nodo
				if (newBollardPosition.length > 1) {
					bollardNodes.push(newBollardPosition);
					newBollardPosition = [];
					continue;
				}
			}

			// ultimo
			if (this.bollards.length > 0) {
				bollardNodes.push(newBollardPosition);
			}

			return bollardNodes;
		},
		berthFilter() {
			this.userBerthIds = this.$store.getters.getUserBerthIds;
			if (this.currentPort && this.currentPort.portid) {
				return {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'id', op: 'in', data: this.userBerthIds }, // berths disponibles para el usuario
						{ field: 'portid', op: 'eq', data: this.currentPort.portid }, // puerto seleccionado
						{ field: 'isanchorage', op: 'ne', data: true } // si es fondeadero no se muestra
					]
				};
			} else {
				return {};
			}
		},
		berthsEnabled() {
			return this.berths && this.berths.length > 0;
		}
	},
	watch: {
		value(v) {
			this.days = v.days;
			this.berths = v.berths;
			this.stops = v.stops;
			this.blocks = v.blocks;
			this.bookings = v.bookings;
			this.bollards = v.bollards;
		},
		lineUpByOperationTypeEnabled() {
			this.checkIntersections();
		},
		zoomFactor() {
			this.setBoard();
		},
		readOnly() {
			this.setBoard();
		},
		onPuiExpandMenu() {
			this.onResize();
		},
		onPuiSecondMenuShowed() {
			this.onResize();
		}
	},
	created() {
		this.$puiEvents.$on('berthplanner-vesselDialog_update', (updatedStop) => {
			this.updateItemValuesFromVueEvent(updatedStop);
		});
	},
	mounted() {
		this.days = this.value.days;
		this.berths = this.value.berths;
		this.stops = this.value.stops;
		this.blocks = this.value.blocks;
		this.bookings = this.value.bookings;
		this.bollards = this.value.bollards;

		this.setBoard();
		this.loadSavedConfiguration();
	},
	destroyed() {
		// this.$puiEvents.$off('berthplanner-vesselDialog_update');
	},
	methods: {
		setBoard() {
			this.jointMain = new JointMain('bp_container', this.$store, this.$puiEvents, this.$puiI18n, this.userFunctionalities, this.zoomFactor);
			this.jointMain.userFunctionalities = this.userFunctionalities;
			this.jointMain.readOnly = this.readOnly;

			// BOLLARDS LENGTH == 0 -> TERMINAL
			this.jointBoard = new JointBollardsBerthBoard(
				this.jointMain,
				this.days,
				this.now,
				this.bollards,
				this.bollardNodes,
				this.berths,
				this.stops,
				this.blocks,
				this.bookings
			);

			let self = this;

			this.jointMain.paper.on('cell:pointerdown', function (cellView, evt, x, y) {
				if (
					cellView.model.operationsModel &&
					(cellView.model.operationsModel.itemType == JointItemTypes.Vessel ||
						cellView.model.operationsModel.itemType == JointItemTypes.Booking)
				) {
					self.jointBoard.boardItems.forEach((boardItem) => {
						if (cellView.model.id == boardItem.itemView.model.id) {
							self.jointMain.jointZManager.toFront(boardItem);
						}
					});
				}
			});

			this.jointMain.paper.on('cell:pointerdblclick', function (cellView, evt, x, y) {
				if (cellView.model.operationsModel) {
					if (cellView.model.operationsModel.itemType == JointItemTypes.Vessel) {
						self.showVesselDialog(cellView.model.operationsModel.stop);
					}
					if (cellView.model.operationsModel.itemType == JointItemTypes.BerthBlock) {
						self.showBerthBlockFormDialog(cellView.model.operationsModel.berthblock);
					}
				}
			});

			//this.jointMain.paper.on('cell:pointerup', function (cellView, evt, x, y) {});

			this.jointBoard.boardItems.forEach((boardItem) => {
				if (boardItem.operationsModel.stop) {
					setTimeout(() => {
						this.setTooltipPosition(boardItem.operationsModel.stop.id, boardItem.operationsModel.position);
					}, 100);
				}

				boardItem.rectangle.on('change:position', (element, position) => {
					if (boardItem.operationsModel.stop) {
						this.setTooltipPosition(boardItem.operationsModel.stop.id, element.attributes.position);
					}

					// POSITION RESTRICTIONS

					const roundedBoardItemLeftBoundary = Math.round(element.attributes.position.x);
					const roundedBoardItemWidth = Math.round(element.attributes.size.width);
					const roundedBoardItemRightBoundary = Math.round(element.attributes.position.x + roundedBoardItemWidth);
					this.debug && console.log('// change:position');
					this.debug && console.log('roundedLeftBoundary ' + this.jointBoard.roundedLeftBoundary);
					this.debug && console.log('roundedBoardItemLeftBoundary ' + roundedBoardItemLeftBoundary);
					this.debug && console.log('roundedBoardItemWidth ' + roundedBoardItemWidth);
					this.debug && console.log('roundedBoardItemRightBoundary ' + roundedBoardItemRightBoundary);
					this.debug && console.log('roundedRightBoundary ' + this.jointBoard.roundedRightBoundary);

					if (roundedBoardItemLeftBoundary < this.jointBoard.roundedLeftBoundary) {
						this.debug && console.log('// position restriction left limit');
						boardItem.rectangle.position(this.jointBoard.roundedLeftBoundary, position.y);
					}

					if (roundedBoardItemRightBoundary > this.jointBoard.roundedRightBoundary) {
						this.debug && console.log('// position restriction right limit');
						boardItem.rectangle.position(this.jointBoard.rightBoundary - roundedBoardItemWidth, position.y);
					}

					// END POSITION RESTRICTIONS

					boardItem.operationsModel.board.dateini = this.jointBoard.jointLeftAxis.getDateFromYPosition(element.attributes.position.y);
					boardItem.operationsModel.board.dateend = this.jointBoard.jointLeftAxis.getDateFromYPosition(
						element.attributes.position.y + element.attributes.size.height
					);

					boardItem.operationsModel.board.bollardini = this.jointBoard.jointTopAxis.getClosestBollardFromXposition(
						element.attributes.position.x
					);
					boardItem.operationsModel.board.bollardend = this.jointBoard.jointTopAxis.getClosestBollardFromXposition(
						element.attributes.position.x + element.attributes.size.width
					);
					if (this.berthsEnabled) {
						boardItem.operationsModel.board.berth = this.jointBoard.getBerthFromBolcode(
							// bollardend porque cuando empaquetamos 2, getBerthFromBolcode está leyendo el primero [0]
							boardItem.operationsModel.board.bollardend.bolcode
						);
					}

					let x1;
					if (boardItem.operationsModel.board.bollardini) {
						x1 = this.jointBoard.jointTopAxis.getXpositionFromTopItemCode(boardItem.operationsModel.board.bollardini.bolcode);
						x1 = Math.round(x1);
					} else {
						x1 = this.jointBoard.roundedLeftBoundary;
					}

					if (x1 + roundedBoardItemWidth > this.jointBoard.roundedRightBoundary) {
						this.debug && console.log('** bollard fit size correction from position event');
						boardItem.rectangle.resize(this.jointBoard.roundedRightBoundary - x1, element.attributes.size.height);
					}

					this.debug && console.log('** bollard fit from position event');
					boardItem.rectangle.position(x1, position.y);

					boardItem.resizeImages();
					this.checkIntersections();
					this.updateItemValuesFromJointEvent(boardItem);
				});

				boardItem.rectangle.on('change:size', (element, size) => {
					// SIZE RESTRICTIONS

					const roundedBoardItemLeftBoundary = Math.round(element.attributes.position.x);
					const roundedBoardItemWidth = Math.round(element.attributes.size.width);
					const roundedBoardItemRightBoundary = Math.round(element.attributes.position.x + roundedBoardItemWidth);
					this.debug && console.log('// change:size');
					this.debug && console.log('roundedLeftBoundary ' + this.jointBoard.roundedLeftBoundary);
					this.debug && console.log('roundedBoardItemLeftBoundary ' + roundedBoardItemLeftBoundary);
					this.debug && console.log('roundedBoardItemWidth ' + roundedBoardItemWidth);
					this.debug && console.log('roundedBoardItemRightBoundary ' + roundedBoardItemRightBoundary);
					this.debug && console.log('roundedRightBoundary ' + this.jointBoard.roundedRightBoundary);

					// minimum width
					if (size.width < this.jointBoard.jointTopAxis.topAxisItemWidth) {
						this.debug && console.log('// size restriction minimum limit');
						boardItem.rectangle.resize(this.jointBoard.jointTopAxis.topAxisItemWidth, size.height);
					}

					// maximum width
					if (size.width > this.jointBoard.maxVesselWidth) {
						this.debug && console.log('// size restriction maximum limit');
						boardItem.rectangle.resize(this.jointBoard.maxVesselWidth, size.height);
					}

					// right overflow
					if (roundedBoardItemRightBoundary > this.jointBoard.roundedRightBoundary) {
						this.debug && console.log('// size restriction right overflow');
						boardItem.rectangle.resize(this.jointBoard.roundedRightBoundary - roundedBoardItemLeftBoundary, size.height);
					}

					// END SIZE RESTRICTIONS

					boardItem.operationsModel.board.dateini = this.jointBoard.jointLeftAxis.getDateFromYPosition(element.attributes.position.y);
					boardItem.operationsModel.board.dateend = this.jointBoard.jointLeftAxis.getDateFromYPosition(
						element.attributes.position.y + element.attributes.size.height
					);

					boardItem.operationsModel.board.bollardini = this.jointBoard.jointTopAxis.getClosestBollardFromXposition(
						element.attributes.position.x
					);
					boardItem.operationsModel.board.bollardend = this.jointBoard.jointTopAxis.getClosestBollardFromXposition(
						element.attributes.position.x + size.width
					);

					if (this.berthsEnabled) {
						boardItem.operationsModel.board.berth = this.jointBoard.getBerthFromBolcode(
							// bollardend porque cuando empaquetamos 2, getBerthFromBolcode está leyendo el primero [0]
							boardItem.operationsModel.board.bollardend.bolcode
						);
					}

					if (boardItem.operationsModel.board.bollardini && boardItem.operationsModel.board.bollardend) {
						let x1 = this.jointBoard.jointTopAxis.getXpositionFromTopItemCode(boardItem.operationsModel.board.bollardini.bolcode);
						let x2 = this.jointBoard.jointTopAxis.getXpositionFromTopItemCode(boardItem.operationsModel.board.bollardend.bolcode);
						let vesselWidth = Math.round(x2) - Math.round(x1);

						if (vesselWidth < this.jointBoard.jointTopAxis.topAxisItemWidth) {
							vesselWidth = this.jointBoard.jointTopAxis.topAxisItemWidth;
						}
						if (vesselWidth > this.jointBoard.maxVesselWidth) {
							vesselWidth = this.jointBoard.maxVesselWidth;
						}

						this.debug && console.log('** bollard fit from size event');
						boardItem.rectangle.resize(vesselWidth, size.height);
					}

					boardItem.resizeImages();
					this.checkIntersections();
					this.updateItemValuesFromJointEvent(boardItem);
				});
			});

			this.checkIntersections();
		},
		setTooltipPosition(id, position) {
			let bpContainer = document.getElementById('bp_container');
			let toolTipIcon = document.getElementById('vesselTooltipIcon_' + id);

			let menuBarWidth = 64;
			if (this.onPuiExpandMenu || this.onPuiSecondMenuShowed) {
				menuBarWidth = 304;
			}
			let margin = 5;

			if (toolTipIcon) {
				toolTipIcon.style.position = 'absolute';
				if (
					position.y < this.jointBoard.topAxisHeight ||
					bpContainer.getBoundingClientRect().top + margin + position.y + 20 + margin >
						bpContainer.getBoundingClientRect().top + bpContainer.getBoundingClientRect().height
				) {
					toolTipIcon.style.visibility = 'hidden';
				} else {
					toolTipIcon.style.visibility = 'visible';
					toolTipIcon.style.opacity = '0';
				}
				toolTipIcon.style.transition = 'none';
				toolTipIcon.style.top = bpContainer.getBoundingClientRect().top + margin + position.y + 'px';
				toolTipIcon.style.left = bpContainer.getBoundingClientRect().left - menuBarWidth + margin + position.x + 'px';
				toolTipIcon.style.zIndex = 99;
			}
		},
		checkIntersections() {
			this.jointBoard.boardItems.forEach((boardItem) => {
				if (boardItem.operationsModel.itemType == JointItemTypes.Vessel) {
					boardItem.operationsModel.validBerth = true;

					if (Object.keys(boardItem.operationsModel.board).length > 0) {
						boardItem.setModifiedColor();
					} else {
						let color = this.lineUpByOperationTypeEnabled
							? boardItem.operationsModel.stop.bpColor || this.getColorFromOperationType(boardItem.operationsModel.stop.operationType)
							: null;
						boardItem.setOriginalColor(color);
					}
				}
			});

			let colorableVesselStatus = ['AC', 'AU'];

			this.jointBoard.boardItems.forEach((boardItem1) => {
				this.jointBoard.boardItems.forEach((boardItem2) => {
					if (boardItem1 != boardItem2) {
						let intersection = boardItem1.getGRect().intersect(boardItem2.getGRect());
						if (intersection !== null && !(boardItem1.operationsModel.deleted || boardItem2.operationsModel.deleted)) {
							boardItem1.operationsModel.validBerth = false;
							boardItem2.operationsModel.validBerth = false;

							if (boardItem1.operationsModel.stop && colorableVesselStatus.includes(boardItem1.operationsModel.stop.statusid))
								boardItem1.setInvalidColor();

							if (boardItem2.operationsModel.stop && colorableVesselStatus.includes(boardItem2.operationsModel.stop.statusid))
								boardItem2.setInvalidColor();
						}
					}
				});
			});
		},
		getColorFromOperationType(operationType) {
			return operationType && this.statusItems && this.statusItems.find((statusItem) => statusItem.description == operationType)?.color;
		},
		updateItemValuesFromVueEvent(updatedStop) {
			/*
			this.jointBoard.boardItems.forEach((vessel) => {
				if (vessel.operationsModel.stop && vessel.operationsModel.stop.id == updatedStop.id) {
					vessel.setSecondLabel(JointUtils.getSecondLabelFromStop(updatedStop));
					vessel.calculateBerthing();
					this.setBoard();
				}
			});
			*/
		},
		updateItemValuesFromJointEvent(boardItem) {
			this.$parent.savingEnabled = true;

			if (boardItem.operationsModel.itemType == JointItemTypes.Vessel) {
				this.stops.forEach((stop) => {
					if (stop.id == boardItem.operationsModel.stop.id) {
						if (boardItem.operationsModel.board.bollardini) {
							stop.bollardiniid = boardItem.operationsModel.board.bollardini.id;
							stop.bollardinicode = boardItem.operationsModel.board.bollardini.bolcode;
							stop.bollardiniorderby = boardItem.operationsModel.board.bollardini.orderby;
						} else {
							stop.bollardiniid = null;
							stop.bollardinicode = null;
							stop.bollardiniorderby = null;
						}

						if (boardItem.operationsModel.board.bollardend) {
							stop.bollardendid = boardItem.operationsModel.board.bollardend.id;
							stop.bollardendcode = boardItem.operationsModel.board.bollardend.bolcode;
							stop.bollardendorderby = boardItem.operationsModel.board.bollardend.orderby;
						} else {
							stop.bollardendid = null;
							stop.bollardendcode = null;
							stop.bollardendorderby = null;
						}

						stop.etalocal = boardItem.operationsModel.board.dateini;
						stop.etdlocal = boardItem.operationsModel.board.dateend;
						stop.eta = stop.etaplanner = stop.etalocal.toISOString();
						stop.etd = stop.etdplanner = stop.etdlocal.toISOString();

						if (this.berthsEnabled) {
							stop.berthid = boardItem.operationsModel.board.berth.id;
						}

						boardItem.setSecondLabel(JointUtils.getSecondLabelFromStop(stop, boardItem.itemView.model.attributes.size.height));
						boardItem.setLabel(JointUtils.getLabelFromStop(stop, boardItem.itemView.model.attributes.size.height));
					}
				});
			}

			if (boardItem.operationsModel.itemType == JointItemTypes.BerthBlock) {
				this.blocks.forEach((berthBlock) => {
					if (berthBlock.id == boardItem.operationsModel.berthblock.id) {
						if (boardItem.operationsModel.board.bollardini) {
							berthBlock.bollardiniid = boardItem.operationsModel.board.bollardini.id;
							berthBlock.bollardinicode = boardItem.operationsModel.board.bollardini.bolcode;
							berthBlock.bollardiniorderby = boardItem.operationsModel.board.bollardini.orderby;
						} else {
							berthBlock.bollardiniid = null;
							berthBlock.bollardinicode = null;
							berthBlock.bollardiniorderby = null;
						}

						if (boardItem.operationsModel.board.bollardend) {
							berthBlock.bollardendid = boardItem.operationsModel.board.bollardend.id;
							berthBlock.bollardendcode = boardItem.operationsModel.board.bollardend.bolcode;
							berthBlock.bollardendorderby = boardItem.operationsModel.board.bollardend.orderby;
						} else {
							berthBlock.bollardendid = null;
							berthBlock.bollardendcode = null;
							berthBlock.bollardendorderby = null;
						}

						berthBlock.dateini = boardItem.operationsModel.board.dateini.toISOString();
						berthBlock.dateend = boardItem.operationsModel.board.dateend.toISOString();

						if (this.berthsEnabled) {
							berthBlock.locationid = boardItem.operationsModel.board.berth.id;
						}

						boardItem.setSecondLabel(JointUtils.getSecondLabelFromBlock(berthBlock, boardItem.itemView.model.attributes.size.height));
						boardItem.setLabel(JointUtils.getLabelFromBlock(berthBlock, boardItem.itemView.model.attributes.size.height));
					}
				});
			}

			if (boardItem.operationsModel.itemType == JointItemTypes.Booking) {
				this.bookings.forEach((booking) => {
					if (booking.id == boardItem.operationsModel.booking.id) {
						if (boardItem.operationsModel.board.bollardini) {
							booking.bollardiniid = boardItem.operationsModel.board.bollardini.id;
							booking.bollardinicode = boardItem.operationsModel.board.bollardini.bolcode;
							booking.bollardiniorderby = boardItem.operationsModel.board.bollardini.orderby;
						} else {
							booking.bollardiniid = null;
							booking.bollardinicode = null;
							booking.bollardiniorderby = null;
						}

						if (boardItem.operationsModel.board.bollardend) {
							booking.bollardendid = boardItem.operationsModel.board.bollardend.id;
							booking.bollardendcode = boardItem.operationsModel.board.bollardend.bolcode;
							booking.bollardendorderby = boardItem.operationsModel.board.bollardend.orderby;
						} else {
							booking.bollardendid = null;
							booking.bollardendcode = null;
							booking.bollardendorderby = null;
						}

						booking.etalocal = booking.etaplannerlocal = boardItem.operationsModel.board.dateini;
						booking.etdlocal = booking.etdplannerlocal = boardItem.operationsModel.board.dateend;
						booking.eta = booking.etaplanner = booking.etalocal.toISOString();
						booking.etd = booking.etdplanner = booking.etdlocal.toISOString();

						if (this.berthsEnabled) {
							booking.berth = boardItem.operationsModel.board.berth.id;
						}

						boardItem.setSecondLabel(JointUtils.getSecondLabelFromBooking(booking, boardItem.itemView.model.attributes.size.height));
						boardItem.setLabel(JointUtils.getLabelFromBooking(booking, boardItem.itemView.model.attributes.size.height));
					}
				});
			}

			this.$emit('input', {
				days: this.days,
				bollards: this.bollards,
				stops: this.stops,
				blocks: this.blocks,
				bookings: this.bookings,
				berths: this.berths
			});
		},
		loadSavedConfiguration() {
			const configuration = this.$store.getters.getBerthPlannerConfiguration;

			configuration.vesselDialogModel &&
				this.stops.forEach((stop) => {
					if (stop.id == configuration.vesselDialogModel.id) {
						this.showVesselDialog(stop);
					}
				});
		},
		showVesselDialog(stop) {
			this.vesselDialogModel = Object.assign({}, stop);
			this.vesselVisibleDialog = stop.vesselimo || stop.vesselmmsi ? true : false;
			if (this.vesselVisibleDialog) {
				this.$store.state.berthPlanner.configuration.vesselDialogModel = stop;
			}
		},
		closeVesselDialog() {
			this.$store.state.berthPlanner.configuration.vesselDialogModel = null;
			this.vesselVisibleDialog = false;
		},
		showBerthBlockFormDialog(berthBlock) {
			this.$store.state.berthPlanner.configuration.berthBlockDialogModel = Object.assign({}, berthBlock);
			this.berthBlockVisibleDialog = true;
		},
		async onResize() {
			this.setBoard();
		}
	}
};
</script>
<style scoped>
#bp_container {
	overflow: hidden;
}
</style>
